import React from 'react';

import './partners.scss';

function Partners() {
  return (
    <>
      <section id='partners' className='partners'>
        <div className='container partners__container'>
          <h2 className='partners_title'>Компания АВРОРАТУЛС</h2>
          <p className='partners_text'>
            С 2018 года ООО «АВРОРАТУЛС» представляет широкую линейку
            металлорежущего инструмента MEGATEC™. Инструмент разрабатывается
            специалистами ООО «АВРОРАТУЛС» совместно с зарубежными R&D центрами
            и изготавливается на ведущих промышленных предприятиях Германии,
            Австрии, Италии и прочих стран. <br /> ООО «АВРОРАТУЛС» является
            правообладателям товарного знака MEGATEC™
          </p>
          <p className='partners_text'>
            Инструмент MEGATEC™ успешно применяется на ведущих промышленных
            предприятиях как в Российской Федерации и странах Таможенного союза
            (Беларусь, Казахстан), так и в странах Европы и Персидского залива.
          </p>
          <p className='partners_text'>
            В программе поставок инструмента MEGATEC™ представлены как самые
            новейшие инструментальные разработки и технологические решения, так
            и современные, но уже проверенные временем и опытом линейки.
            Инструмент MEGATEC™ позволяет обеспечивать высокую
            производительность и износостойкость при разумном уровне затрат.
          </p>
        </div>
      </section>
    </>
  );
}

export default Partners;
